import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Home/ContentServicesHome";
import HeroSection from "../components/Home/HeroSection";
import Paletacolor from "../components/global/Paletacolor";
import BlockPrincipal from "../components/block/Block_1";
import BlockSecondary from "../components/block/Block_4";
import BlockAbout from "../components/block/Block_1";
import VideoPromocional from "../components/global/VideoPromocional";
import ContentServices from "../components/Services/ContentServices";
import GalleryContent from "../components/Gallery/GalleryContent";
import Values from "../components/values/Values_1";
// import Videos from "../components/global/Videos";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <Modal />

        <HeroSection
          Img1={rpdata?.gallery?.[0]}
          Img2={rpdata?.gallery?.[1]}
          Img3={rpdata?.gallery?.[2]}
          Img4={rpdata?.gallery?.[3]}
        />
        <BlockPrincipal
          text={rpdata?.dbHome?.[0].text}
          image={rpdata?.gallery?.[1]}
          sloganPrincipal={true}
          listsServices={true}
        />
        <Values
          image={rpdata?.gallery?.[5]}
        />
        <BlockSecondary
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image1={rpdata?.gallery?.[15]}
          image2={rpdata?.gallery?.[18]}
          image3={rpdata?.gallery?.[12]}
          
        />
        <Directories />
        {/* video promocional */}
        {
          rpdata?.videosPromo?.[0]?.activo ?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={`${rpdata?.videosPromo?.[0].img}`}
            />
            : null
        }

        {/* <Videos/> */}

        <CounterUp image={rpdata?.gallery?.[6]} />
        <BlockAbout
          title={'a little about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.gallery?.[20]}
          listsAbout={true}
        />

        <div className="bg-2 py-10 md:py-14">
          <div className="flex flex-col md:flex-row justify-center md:justify-around items-center">
            <h2 className="text-white text-center md:text-start pb-10 md:pb-0">{rpdata?.dbSlogan?.[4]?.slogan}</h2>
            <div>
              <ul className="flex">
                {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                  return (
                    <li key={index} className="mr-3 md:mr-7 bg-white px-2.5 py-1 rounded-md">
                      <a
                        href={item.url}
                        alt={item.name}
                        aria-label={item.name}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="textColor1"
                      >
                        <i className={`fab fa-${item.icon}`} aria-hidden="true" />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        {/* our reviews */}
        {
          rpdata?.reviews?.isHomeOnly === true ?
            <div className="w-4/5 mx-auto py-[100px]">
              <h1 className="pb-10 text-center">{rpdata?.labels?.general?.titleReviews}</h1>
              <div className={`${rpdata?.reviews?.links?.[0]}`}></div>
            </div>
            : null
        }

        {/* Servicios del home */}
        {
          rpdata?.simpleWidgets?.[3]?.activo ?
            <div className="pt-20">
              <ContentServices />
            </div>
            :
            <ServicesHome />

        }

        {/* Gallery cuando es OnePages */}

        {/* Paleta de Colores */}
        {
          rpdata?.simpleWidgets?.[3]?.activo ?
            <div className="pt-10 pb-28">
              <h2 className="text-center pb-[50px]">{rpdata?.labels?.general?.titleGallery}</h2>
              <GalleryContent galleryImages={rpdata?.gallery?.length > 0 ? rpdata?.gallery : rpdata?.gallery} />
            </div>
            :
            null

        }
        {
          rpdata?.brandingExtra?.[0]?.activo ?
            <Paletacolor />
            : null
        }
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.gallery?.[8]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>

        <Map />

      </div>
    </BaseLayout>
  );
}

export default Home;
